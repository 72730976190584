import EditLoanCollateralDecreeReschedule from "./collateral-decree-reschedule";
import EditLoanCollateralFixedAssetReschedule from "./collateral-fixed-asset-reschedule";
import EditLoanCollateralSecuritiesReschedule from "./collateral-securities-reschedule";
import EditLoanCollateralVehicleReschedule from "./collateral-vehicle-reschedule";
import EditLoanCollateralPreciousMetalReschedule from "./collateral-precious-metal-reschedule";

export default {
  name: "EditLoanCollateralReschedule",
  components: {
    EditLoanCollateralDecreeReschedule,
    EditLoanCollateralFixedAssetReschedule,
    EditLoanCollateralSecuritiesReschedule,
    EditLoanCollateralVehicleReschedule,
    EditLoanCollateralPreciousMetalReschedule,
  },
};
