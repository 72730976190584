import Mixins from "../../Mixins.js";

export default {
  name: "EditLoanBiCodeReschedule",
  components: {},
  mixins: [Mixins],
  data() {
    return {
      property: {
        tabs: {
          step5: {
            downloading: false,
            isEditable: false,
            loanBiCodeActionType: "1",
          },
        },
        animation: {
          step5: {
            add: {
              isLoading: false,
            },
            edit: {
              isLoading: false,
            },
          },
        },
      },
      dataForm: {
        step5: {
          pilihNomorKontrak: "",
          add: {
            data: [],
            rawData: [],
            renderedForm: [],
          },
          edit: {
            data: [],
            renderedForm: [],
            rawData: [],
          },
        },
      },
    };
  },
  methods: {
    changeSelectOptionContractNumberSetupSandiBi() {
      this.dataForm.step5.add.data = [];
      this.dataForm.step5.add.rawData = [];
      this.dataForm.step5.add.renderedForm = [];
      this.dataForm.step5.edit.rawData = [];
      this.dataForm.step5.edit.data = [];
      this.dataForm.step5.edit.renderedForm = [];
      this.property.tabs.step5.isEditable = false;
      this.property.tabs.step5.downloading = false;
      if (this.dataForm.step5.pilihNomorKontrak === "") {
      } else {
        this.mixin_data_options_loan_bi_code_loan_account.map((index) => {
          if (index.value === this.dataForm.step5.pilihNomorKontrak) {
            this.getLoanBiCodeById();
          }
        });
      }
    },
    async aggregateDataLoanBiCodeAdd(resp) {
      const right = resp.data.data;
      const left = resp.data.data;
      right.map((indexLevel1) => {
        var sandiBiLevel1Code = indexLevel1.sandiBiLevel1Code;
        var sandiBiLevel2Code = indexLevel1.sandiBiLevel2Code;
        var sandiBi = left.filter(
          (sandiBiIndex) =>
            sandiBiIndex.sandiBiLevel1Code === indexLevel1.sandiBiLevel1Code &&
            sandiBiIndex.sandiBiLevel2Code === indexLevel1.sandiBiLevel2Code
        );
        var obj = {
          sandiBiLevel1Code,
          sandiBiLevel1Name: indexLevel1.sandiBiLevel1Name,
          sandiBiLevel2Code,
          sandiBiLevel2Name: indexLevel1.sandiBiLevel2Name,
          sandiBiSelected: "",
          loanBiCodeId: "",
          isExistingFromDB: false,
          sandiBi,
        };

        var mathingWithExisting = this.dataForm.step5.add.data.filter(
          (mathingWithExistingIndex) =>
            mathingWithExistingIndex.sandiBiLevel1Code === sandiBiLevel1Code &&
            mathingWithExistingIndex.sandiBiLevel2Code === sandiBiLevel2Code
        );
        console.log(mathingWithExisting);
        if (mathingWithExisting.length > 0) {
          obj.sandiBiSelected = mathingWithExisting[0].sandiBiCode;
          obj.isExistingFromDB = true;
          obj.loanBiCodeId = mathingWithExisting[0].loanBiCodeId;
        }
        var findingexistingRaw = this.dataForm.step5.add.rawData.filter(
          (findingexistingRawIndex) =>
            findingexistingRawIndex.sandiBiLevel1Code === sandiBiLevel1Code &&
            findingexistingRawIndex.sandiBiLevel2Code === sandiBiLevel2Code
        );
        if (findingexistingRaw.length === 0) {
          this.dataForm.step5.add.rawData.push(obj);
        }
      });
      this.dataForm.step5.add.rawData.map((rawData) => {
        const findMatching = this.dataForm.step5.add.rawData.filter(
          (i) => i.sandiBiLevel1Code === rawData.sandiBiLevel1Code
        );
        const obj = {
          sandiBiLevel1Name: rawData.sandiBiLevel1Name,
          sandiBiLevel1Code: rawData.sandiBiLevel1Code,
          sandiBiLevel2: findMatching,
        };
        var findingexisting = this.dataForm.step5.add.renderedForm.filter(
          (i) => i.sandiBiLevel1Code === obj.sandiBiLevel1Code
        );
        if (findingexisting.length === 0) {
          this.dataForm.step5.add.renderedForm.push(obj);
        }
      });
    },
    async aggregateDataLoanBiCodeEdit(resp) {
      const right = resp.data.data;
      const left = resp.data.data;
      right.map((indexLevel1) => {
        var sandiBiLevel1Code = indexLevel1.sandiBiLevel1Code;
        var sandiBiLevel2Code = indexLevel1.sandiBiLevel2Code;
        var sandiBi = left.filter(
          (sandiBiIndex) =>
            sandiBiIndex.sandiBiLevel1Code === indexLevel1.sandiBiLevel1Code &&
            sandiBiIndex.sandiBiLevel2Code === indexLevel1.sandiBiLevel2Code
        );
        var obj = {
          sandiBiLevel1Code,
          sandiBiLevel1Name: indexLevel1.sandiBiLevel1Name,
          sandiBiLevel2Code,
          sandiBiLevel2Name: indexLevel1.sandiBiLevel2Name,
          sandiBiSelected: "",
          loanBiCodeId: "",
          isExistingFromDB: false,
          sandiBi,
        };

        var mathingWithExisting = this.dataForm.step5.edit.data.filter(
          (mathingWithExistingIndex) =>
            mathingWithExistingIndex.sandiBiLevel1Code === sandiBiLevel1Code &&
            mathingWithExistingIndex.sandiBiLevel2Code === sandiBiLevel2Code
        );
        console.log(mathingWithExisting);
        if (mathingWithExisting.length > 0) {
          obj.sandiBiSelected = mathingWithExisting[0].sandiBiCode;
          obj.isExistingFromDB = true;
          obj.loanBiCodeId = mathingWithExisting[0].loanBiCodeId;
        }
        var findingexistingRaw = this.dataForm.step5.edit.rawData.filter(
          (findingexistingRawIndex) =>
            findingexistingRawIndex.sandiBiLevel1Code === sandiBiLevel1Code &&
            findingexistingRawIndex.sandiBiLevel2Code === sandiBiLevel2Code
        );
        if (findingexistingRaw.length === 0) {
          this.dataForm.step5.edit.rawData.push(obj);
        }
      });
      this.dataForm.step5.edit.rawData.map((rawData) => {
        const findMatching = this.dataForm.step5.edit.rawData.filter(
          (i) => i.sandiBiLevel1Code === rawData.sandiBiLevel1Code
        );
        const obj = {
          sandiBiLevel1Name: rawData.sandiBiLevel1Name,
          sandiBiLevel1Code: rawData.sandiBiLevel1Code,
          sandiBiLevel2: findMatching,
        };
        var findingexisting = this.dataForm.step5.edit.renderedForm.filter(
          (i) => i.sandiBiLevel1Code === obj.sandiBiLevel1Code
        );
        if (findingexisting.length === 0) {
          this.dataForm.step5.edit.renderedForm.push(obj);
        }
      });
    },
    async getLoanBiCodeById() {
      try {
        this.property.tabs.step5.downloading = true;
        const response = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "loan",
          reqUrl:
            "loan-bi-code/loan-account-id-v2/" +
            this.dataForm.step5.pilihNomorKontrak,
        });
        if (response.data.code === "SUCCESS") {
          this.property.tabs.step5.isEditable = true;
          this.dataForm.step5.add.data = response.data.data;
          this.dataForm.step5.edit.data = response.data.data;
          this.getSandiBiCodeMapping("exist");
        } else {
          this.dataForm.step5.add.data = [];
          this.getSandiBiCodeMapping("new");
        }
      } catch (error) {
        this.property.tabs.step5.downloading = false;
      } finally {
      }
    },
    async getSandiBiCodeMapping(type) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "parameter",
            reqUrl: "sandi-bi-code-mapping/list",
          });
          if (resp.data.code === "SUCCESS") {
            switch (type) {
              case "exist":
                this.aggregateDataLoanBiCodeAdd(resp);
                this.aggregateDataLoanBiCodeEdit(resp);
                break;
              case "new":
                this.aggregateDataLoanBiCodeAdd(resp);
                break;
              default:
                break;
            }
          }
          this.property.tabs.step5.downloading = false;
        } catch (error) {
          console.log(error.response);
        }
      }
    },
    async addLoanBiCode() {
      var payload = [];
      this.dataForm.step5.add.rawData.map((rawDataIndex) => {
        if (rawDataIndex.sandiBiSelected !== "") {
          console.log(rawDataIndex);
          var findingexisting = this.dataForm.step5.add.data.filter(
            (findingexistingIndex) =>
              findingexistingIndex.sandiBiLevel1Code ===
                rawDataIndex.sandiBiLevel1Code &&
              findingexistingIndex.sandiBiLevel2Code ===
                rawDataIndex.sandiBiLevel2Code
          );
          if (findingexisting.length === 0) {
            const sandiBiLevel1Code = rawDataIndex.sandiBiLevel1Code;
            const sandiBiLevel2Code = rawDataIndex.sandiBiLevel2Code;
            const sandiBiCode = rawDataIndex.sandiBiSelected;
            const tloanAccountId = this.dataForm.step5.pilihNomorKontrak;
            payload.push({
              sandiBiLevel1Code,
              sandiBiLevel2Code,
              sandiBiCode,
              tloanAccountId,
            });
          }
        }
      });
      console.log(payload);
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.step5.add.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                reqUrl: "loan-bi-code/bulk",
                endPoint: "loan",
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  const toastBuefy = this.$buefy.toast.open(
                    this.$NotificationUtils.success
                  );
                  toastBuefy.$on("close", () => {
                    this.changeSelectOptionContractNumberSetupSandiBi();
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1000);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              this.property.animation.step5.add.isLoading = false;
            }
          },
        });
      }
    },
    async editLoanBiCode() {
      var payload = [];
      this.dataForm.step5.edit.rawData.map((rawDataIndex) => {
        if (rawDataIndex.sandiBiSelected !== "") {
          console.log(rawDataIndex);
          var findingexisting = this.dataForm.step5.edit.data.filter(
            (findingexistingIndex) =>
              findingexistingIndex.sandiBiLevel1Code ===
                rawDataIndex.sandiBiLevel1Code &&
              findingexistingIndex.sandiBiLevel2Code ===
                rawDataIndex.sandiBiLevel2Code &&
              findingexistingIndex.sandiBiCode !== rawDataIndex.sandiBiSelected
          );
          if (findingexisting.length > 0) {
            const sandiBiLevel1Code = rawDataIndex.sandiBiLevel1Code;
            const sandiBiLevel2Code = rawDataIndex.sandiBiLevel2Code;
            const sandiBiCode = rawDataIndex.sandiBiSelected;
            const tloanAccountId = this.dataForm.step5.pilihNomorKontrak;
            const loanBiCodeId = rawDataIndex.loanBiCodeId;
            payload.push({
              sandiBiLevel1Code,
              sandiBiLevel2Code,
              sandiBiCode,
              tloanAccountId,
              loanBiCodeId,
            });
          }
        }
      });
      console.log(payload);
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.step5.edit.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                reqUrl: "loan-bi-code/bulk",
                endPoint: "loan",
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  const toastBuefy = this.$buefy.toast.open(
                    this.$NotificationUtils.success
                  );
                  toastBuefy.$on("close", () => {
                    this.changeSelectOptionContractNumberSetupSandiBi();
                  });
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1000);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              this.property.animation.step5.edit.isLoading = false;
            }
          },
        });
      }
    },
  },
  mounted() {},
};
