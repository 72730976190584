import LoanInstallmentUploadForm from "@/components/page/loan-installment-upload-form";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Mixins from "../../Mixins.js";

export default {
  mixins: [Mixins],
  name: "EditLoanInstallmenteReschedule",
  components: {
    LoanInstallmentUploadForm,
  },
  data() {
    return {
      property: {
        modal: {
          step3: {
            showModalEditMarginInstallment: false,
          },
        },
        listElement: {
          step3: {
            downloading: false,
            message: "",
          },
        },
        animation: {
          cetakNasabah: {
            isLoading: false,
          },
          step3: {
            processGenerateInstallment: {
              isLoading: false,
            },
            updateMargin: {
              isLoading: false,
            },
          },
        },
      },
      dataForm: {
        step3: {
          request: {
            statusId: "",
            pilihNomorKontrak: "",
            cifIdName: "",
            officeName: "",
            realitationDate: "",
            timePeriod: "",
            totalMargin: "",
            dueDate: "",
            nominalLoan: "",
            loanAccountId: "",
            loanAccountNumber: "",
            postponePrincipal: 0,
            reschedulePrincipal: 0,
            unpaid: 0,
            rateMarginTotal: 0,
            principalUnpaid: 0,
            installmentPrincipal: 0,
            flatPrincipal: 0,
            installmentMargin: 0,
            loanAccountNumberNew: "",
            typeAngsuran: "",
            margin: 0,
            principal: 0,
            billDay: "",
            loanAkadTypeAcronym: "",
            updateMargin: {
              newMargin: 0,
              loanInstallmentId: "",
              newPrincipal: 0,
            },
          },
          response: {
            dueDate: "",
            nominalLoan: 0,
            totalPeriod: 0,
            deferredPrincipal: 0,
            reschedulePrincipal: 0,
            billDay: "",
          },
        },
      },
      options: {
        step3: {
          pilihNomorKontrak: [],
          typeAngsuran: [],
        },
      },
      table: {
        data: {
          step3: [],
        },
        header: {
          step3: [
            {
              key: "no",
              label: "No",
            },

            {
              key: "dueDate",
              label: "Tanggal Jatuh Tempo",
            },
            {
              key: "principalRemain",
              label: "Sisa Pokok",
            },
            {
              key: "principal",
              label: "Angsuran Pokok",
            },
            {
              key: "margin",
              label: "Angsuran Margin",
            },
            {
              key: "installment",
              label: "Total Angsuran",
            },
            {
              key: "rloanPaymentStatus.paymentStatusName",
              label: "Status Pembayaran",
            },
          ],
        },
      },
    };
  },
  methods: {
    async onDownload() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        loanAccountNumber: this.dataForm.step3.request.loanAccountNumber,
        fileType: "pdf",
        name: this.dataForm.step3.request.cifIdName,
      };
      this.property.animation.cetakNasabah.isLoading = true;
      try {
        const response = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/angsuran-nasabah/files",
          payload: payload,
        });
        this.printOutWindow(response.data);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.cetakNasabah.isLoading = false;
      }
    },
    onUploadLoanInstallment() {
      this.$refs.loanInstallmentUploadFormTRef.onOpenModal();
    },
    showModalUpdateMarginLoanInstallment(props) {
      this.dataForm.step3.request.updateMargin.newMargin = 0;
      this.dataForm.step3.request.updateMargin.newPrincipal = 0;
      this.dataForm.step3.request.updateMargin.loanInstallmentId = "";
      this.property.modal.step3.showModalEditMarginInstallment = true;
      this.dataForm.step3.request.updateMargin.newMargin = props.row.margin;
      this.dataForm.step3.request.updateMargin.newPrincipal =
        props.row.principal;
      this.dataForm.step3.request.updateMargin.loanInstallmentId =
        props.row.loanInstallmentId;
    },
    async editMarginLoanInstallment() {
      const payload = {
        loanInstallmentId: this.dataForm.step3.request.updateMargin
          .loanInstallmentId,
        newMargin: this.dataForm.step3.request.updateMargin.newMargin,
        newPrincipal: this.dataForm.step3.request.updateMargin.newPrincipal,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.step3.updateMargin.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "loan",
                reqUrl: "loan-installment/update-margin",
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.closeModalUpdateMarginLoanInstallment();
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                  setTimeout(() => {
                    this.changeOptionSelectContractNumberInstallment();
                  }, 1000);
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.step3.updateMargin.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    routeToPageAddLoanInstallmentManualPayment(props) {
      this.$router.push(
        `/loan/installment/manual-payment/new?refId=${this.encryptBASE64(
          JSON.stringify({ loanAccountNumber: props.row.loanAccountNumber })
        )}`
      );
    },
    closeModalUpdateMarginLoanInstallment() {
      this.property.modal.step3.showModalEditMarginInstallment = false;
      this.dataForm.step3.request.updateMargin.newMargin = 0;
      this.dataForm.step3.request.updateMargin.newPrincipal = 0;
      this.dataForm.step3.request.updateMargin.loanInstallmentId = "";
    },
    async changeOptionSelectContractNumberInstallment() {
      this.resetFormRequestResponseInstallment();
      if (this.dataForm.step3.request.pilihNomorKontrak === "") {
        this.table.data.step3 = [];
      } else {
        if (window.navigator.onLine === false) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        } else {
          try {
            const resp = await this.$store.dispatch({
              type: "GET_DATA_FIND_BY",
              endPoint: "loan",
              reqUrl:
                "transaction-loan/findByLoanAccountNumber/" +
                this.dataForm.step3.request.pilihNomorKontrak,
            });
            if (resp.data.code === "SUCCESS") {
              console.log(resp.data.data);
              this.dataForm.step3.request.billDay = resp.data.data.billDay;
              this.dataForm.step3.request.margin =
                resp.data.data.mloan.rloanMarginCalculate
                  .loanMarginCalculateId === "1"
                  ? resp.data.data.rateFlat
                  : resp.data.data.rateEffective;
              this.dataForm.step3.request.createdByUserCode =
                resp.data.data.createdByUserCode;
              this.dataForm.step3.request.createdByUserName =
                resp.data.data.createdByUserName;
              this.dataForm.step3.request.createdDate =
                resp.data.data.createdDate;
              this.dataForm.step3.request.updatedDate =
                resp.data.data.updatedDate;
              this.dataForm.step3.request.updatedByUserCode =
                resp.data.data.updatedByUserCode;
              this.dataForm.step3.request.updatedByUserName =
                resp.data.data.updatedByUserName;
              this.dataForm.step3.request.authorizedDate =
                resp.data.data.authorizedDate;
              this.dataForm.step3.request.authorizedByUserCode =
                resp.data.data.authorizedByUserCode;
              this.dataForm.step3.request.authorizedByUserName =
                resp.data.data.authorizedByUserName;
              this.dataForm.step3.request.loanAkadTypeAcronym =
                resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym;
              this.dataForm.step3.request.statusId =
                resp.data.data.rstatus.statusId;
              this.dataForm.step3.request.principal =
                resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                  this.fetchAppSession("@vue-session/config-bundle")
                    .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
                resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                  this.fetchAppSession("@vue-session/config-bundle")
                    .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
                  ? resp.data.data.participateBank
                  : resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                    this.fetchAppSession("@vue-session/config-bundle")
                      .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH
                  ? resp.data.data.nominalPurchase
                  : resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                    this.fetchAppSession("@vue-session/config-bundle")
                      .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_QARDH
                  ? resp.data.data.nominalLoan
                  : 0;
              this.table.data.step3 = [];
              this.property.listElement.step3.downloading = true;
              try {
                const response = await this.$store.dispatch({
                  type: "GET_DATA_FIND_BY",
                  endPoint: "loan",
                  reqUrl:
                    "loan-installment/findByLoanAccountId/" +
                    resp.data.data.loanAccountId,
                });
                if (response.data.code === "SUCCESS") {
                  this.dataForm.step3.response.billDay =
                    response.data.data.billDay;
                  this.dataForm.step3.response.dueDate =
                    response.data.data.dueDate;
                  this.dataForm.step3.response.nominalLoan =
                    response.data.data.nominalLoan;

                  this.dataForm.step3.response.deferredPrincipal =
                    response.data.data.deferredPrincipal;
                  this.dataForm.step3.request.realitationDate =
                    response.data.data.realitationDate;
                  this.dataForm.step3.response.reschedulePrincipal =
                    response.data.data.reschedulePrincipal;
                  this.table.data.step3 = response.data.data.detailInstallment;
                  this.getLoanInstallmentByLoanAccountNumber(response);
                } else {
                  this.table.data.step3 = [];
                  this.property.listElement.step3.message =
                    response.data.message;
                  this.getLoanInstallmentByLoanAccountNumberOld(resp);
                }
              } catch (error) {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
                this.property.listElement.step3.message = error.response
                  ? `Gagal Mengunduh Data : ${error.response.statusText}`
                  : "Terjadi Kesalahan";
              } finally {
                this.property.listElement.step3.downloading = false;
              }
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          } catch (error) {
            console.log(error.response);
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
            this.property.listElement.step3.message = error.response
              ? `Gagal Mengunduh Data : ${error.response.statusText}`
              : "Terjadi Kesalahan";
          }
        }
      }
    },
    resetFormRequestResponseInstallment() {
      this.dataForm.step3.request.cifIdName = "";
      this.dataForm.step3.request.officeName = "";
      this.dataForm.step3.request.realitationDate = "";
      this.dataForm.step3.request.timePeriod = "";
      this.dataForm.step3.request.totalMargin = "";
      this.dataForm.step3.request.dueDate = "";
      this.dataForm.step3.request.nominalLoan = "";
      this.dataForm.step3.request.loanAccountId = "";
      this.dataForm.step3.request.loanAccountNumber = "";
      this.dataForm.step3.request.postponePrincipal = 0;
      this.dataForm.step3.request.reschedulePrincipal = 0;
      this.dataForm.step3.request.reschedulePrincipal = 0;
      this.dataForm.step3.request.unpaid = 0;
      this.dataForm.step3.request.rateMarginTotal = 0;
      this.dataForm.step3.request.principalUnpaid = 0;
      this.dataForm.step3.request.installmentPrincipal = 0;
      this.dataForm.step3.request.flatPrincipal = 0;
      this.dataForm.step3.request.installmentMargin = 0;
      this.dataForm.step3.request.loanAccountNumberNew = 0;
      this.dataForm.step3.request.typeAngsuran = "";
      this.dataForm.step3.response.dueDate = "";
      this.dataForm.step3.response.totalPeriod = 0;
      this.dataForm.step3.response.nominalLoan = 0;
      this.dataForm.step3.response.reschedulePrincipal = 0;
      this.dataForm.step3.response.deferredPrincipal = 0;
      this.dataForm.step3.request.margin = 0;
      this.dataForm.step3.request.principal = 0;
      this.dataForm.step3.request.loanAkadTypeAcronym = "";
    },
    async getLoanInstallmentByLoanAccountNumber(response) {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "loan",
          reqUrl:
            "loan-installment/findByLoanAccountNumber/" +
            response.data.data.contractNumber,
        });
        if (resp.data.code === "SUCCESS") {
          this.dataForm.step3.request.cifIdName = resp.data.data.cifIdName;
          this.dataForm.step3.request.realitationDate =
            response.data.data.realitationDate;
          this.dataForm.step3.request.officeName = resp.data.data.officeName;
          this.dataForm.step3.request.timePeriod = resp.data.data.timePeriod;
          this.dataForm.step3.request.nominalLoan = resp.data.data.nominalLoan;
          this.dataForm.step3.request.dueDate = resp.data.data.dueDate;
          this.dataForm.step3.request.loanAccountId =
            resp.data.data.loanAccountId;
          this.dataForm.step3.request.loanAccountNumber =
            resp.data.data.loanAccountNumber;
          this.dataForm.step3.request.timePeriod = resp.data.data.timePeriod;
          this.dataForm.step3.request.unpaid = resp.data.data.unpaid;

          this.dataForm.step3.request.rateMarginTotal =
            resp.data.data.rateMarginTotal;
          this.dataForm.step3.request.principalUnpaid =
            resp.data.data.principalUnpaid;
          this.dataForm.step3.request.installmentPrincipal =
            resp.data.data.installmentPrincipal;
          this.dataForm.step3.request.flatPrincipal =
            resp.data.data.flatPrincipal;
          this.dataForm.step3.request.installmentMargin =
            resp.data.data.installmentMargin;
          this.dataForm.step3.response.totalPeriod = resp.data.data.unpaid;
        }
      } catch (error) {}
    },
    async getLoanInstallmentByLoanAccountNumberOld(resp) {
      try {
        const response = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "loan",
          reqUrl:
            "loan-installment/findByLoanAccountNumber/" +
            resp.data.data.loanAccountParent.loanAccountNumber,
        });
        if (response.data.code === "SUCCESS") {
          this.dataForm.step3.request.cifIdName = response.data.data.cifIdName;
          this.dataForm.step3.request.realitationDate =
            response.data.data.realitationDate;
          this.dataForm.step3.request.officeName =
            response.data.data.officeName;
          this.dataForm.step3.request.timePeriod =
            response.data.data.timePeriod;
          this.dataForm.step3.request.nominalLoan =
            response.data.data.nominalLoan;
          this.dataForm.step3.request.dueDate = response.data.data.dueDate;
          this.dataForm.step3.request.loanAccountId =
            response.data.data.loanAccountId;
          this.dataForm.step3.request.loanAccountNumber =
            response.data.data.loanAccountNumber;
          this.dataForm.step3.request.timePeriod =
            response.data.data.timePeriod;
          this.dataForm.step3.request.unpaid = response.data.data.unpaid;
          this.dataForm.step3.request.rateMarginTotal =
            response.data.data.rateMarginTotal;
          this.dataForm.step3.request.principalUnpaid =
            response.data.data.principalUnpaid;
          this.dataForm.step3.request.installmentPrincipal =
            response.data.data.installmentPrincipal;
          this.dataForm.step3.request.flatPrincipal =
            response.data.data.flatPrincipal;
          this.dataForm.step3.request.installmentMargin =
            response.data.data.installmentMargin;
          this.dataForm.step3.request.postponePrincipal =
            response.data.data.flatPrincipal * response.data.data.unpaid;
        }
      } catch (error) {}
    },
    changeReschedulePrincipal() {
      if (this.dataForm.step3.request.reschedulePrincipal === 0) {
        this.dataForm.step3.request.postponePrincipal =
          this.dataForm.step3.request.flatPrincipal *
          this.dataForm.step3.request.unpaid;
      } else if (this.dataForm.step3.request.reschedulePrincipal > 0) {
        this.dataForm.step3.request.postponePrincipal =
          this.dataForm.step3.request.flatPrincipal -
          this.dataForm.step3.request.reschedulePrincipal;
      }
    },
    async processLoanInstallmentGenerateReschedule(props) {
      console.log(props);
      const payload = {
        contractNumber: this.dataForm.step3.request.pilihNomorKontrak,
        postponePrincipal: this.dataForm.step3.request.postponePrincipal,
        reschedulePrincipal: this.dataForm.step3.request.reschedulePrincipal,
      };
      console.log(JSON.stringify(payload));
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.step3.processGenerateInstallment.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "loan",
                reqUrl:
                  "loan-installment/generate-reschedule/" +
                  this.dataForm.step3.request.typeAngsuran,
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                  setTimeout(() => {
                    this.dataForm.step3.response.dueDate =
                      resp.data.data.dueDate;
                    this.dataForm.step3.response.nominalLoan =
                      resp.data.data.nominalLoan;
                    this.dataForm.step3.response.deferredPrincipal =
                      resp.data.data.deferredPrincipal;
                    this.dataForm.step3.response.reschedulePrincipal =
                      resp.data.data.reschedulePrincipal;
                    this.table.data.step3 = resp.data.data.detailInstallment;
                  }, 500);
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.step3.processGenerateInstallment.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    printLoanInstallment() {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      const headerFinal = [];
      const header = this.table.header.step3.map((index) => {
        headerFinal.push(index.label);
      });
      if (headerFinal.length > 7) {
        headerFinal.length = 7;
      }
      const finalDataTable = [];
      var numbering = 1;
      const loopDataTable = this.table.data.step3.map((index) => {
        finalDataTable.push([
          numbering,
          `${
            index.principalRemain
              ? index.principalRemain
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          `${
            index.principal
              ? index.principal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          `${
            index.margin
              ? index.margin.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          `${
            index.installment
              ? index.installment.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          `${
            index.rloanPaymentStatus
              ? index.rloanPaymentStatus.paymentStatusName
              : ""
          }`,
          `${index.rstatus ? index.rstatus.statusName : ""}`,
        ]);
        numbering = numbering + 1;
      });
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      doc
        .setFontSize(10)
        .text("Data Angsuran Pembiayaan", pageWidth / 2, 0.8, "center");
      doc
        .setFontSize(8)
        .text(
          `Nomor Kontrak         :   ${this.dataForm.step3.request.pilihNomorKontrak}`,
          0.55,
          1.3
        );
      doc
        .setFontSize(8)
        .text(
          `Nama                        :   ${this.dataForm.step3.request.cifIdName}`,
          0.55,
          1.5
        );
      doc
        .setFontSize(8)
        .text(
          `Jangka Waktu           :  ${this.dataForm.step3.request.timePeriod} Bulan`,
          0.55,
          1.7
        );
      doc
        .setFontSize(8)
        .text(
          `Sisa Jangka Waktu   :  ${this.dataForm.step3.request.unpaid} Bulan`,
          0.55,
          1.9
        );
      doc
        .setFontSize(8)
        .text(
          `Jatuh Tempo             :   ${this.dateToYearMonthDay(
            this.dataForm.step3.request.dueDate
          )} `,
          0.55,
          2.1
        );
      doc
        .setFontSize(8)
        .text(
          `Nominal Pokok  :   ${this.dataForm.step3.request.principal
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`,
          pageWidth / 2 + 1,
          1.3
        );
      doc
        .setFontSize(8)
        .text(
          `Total Margin      :   ${this.dataForm.step3.request.margin} %`,
          pageWidth / 2 + 1,
          1.5
        );
      doc
        .setFontSize(8)
        .text(
          `Sisa Pokok        :   ${
            this.dataForm.step3.request.principalUnpaid
              ? this.dataForm.step3.request.principalUnpaid
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          pageWidth / 2 + 1,
          1.7
        );

      doc.autoTable({
        columnStyles: {
          0: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          1: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          2: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          3: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          4: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          5: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          6: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          7: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          8: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
        },
        headStyles: {
          halign: "center",
          fontSize: 7,
          fontStyle: "bold",
          font: "normal",
        },
        head: [headerFinal],
        theme: "striped",
        body: finalDataTable,
        margin: { top: 2.3 },
        halign: "center",
        didDrawPage: function(data) {
          data.settings.margin.top = 0.9;
        },
      });
      doc.autoPrint();

      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      // "visibility: hidden" would trigger safety rules in some browsers like safari，
      // in which the iframe display in a pretty small size instead of hidden.
      // here is some little hack ~
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
    appendDefaultOptionSelect() {
      this.options.step3.typeAngsuran = [
        { value: "", text: "-- Pilih --" },
        { value: "1", text: "Rekondisi" },
        { value: "2", text: "Reschedule" },
      ];
    },
  },
  mounted() {
    this.appendDefaultOptionSelect();
  },
};
