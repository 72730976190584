import EditLoanAccountReschedule from "./loan-account-reschedule";
import EditLoanBiCodeReschedule from "./loan-bi-code-reschedule";
import EditLoanCollateralReschedule from "./loan-collateral-reschedule";
import EditLoanDocumentChecklistReschedule from "./loan-document-checklist-reschedule";
import EditLoanInstallmentReschedule from "./loan-installment-reschedule";
import Mixins from "../Mixins.js";

export default {
  mixins: [Mixins],
  name: "EditTLoanAccountReschedule",
  components: {
    EditLoanAccountReschedule,
    EditLoanBiCodeReschedule,
    EditLoanCollateralReschedule,
    EditLoanDocumentChecklistReschedule,
    EditLoanInstallmentReschedule,
  },
  destroyed() {
    this.$store.dispatch("CLEAR_OPTIONS_LOAN_ACCOUT");
  },
  methods: {
    centralBackToList() {
      this.$router.push("/loan/loan-account-reschedule");
    },
  },
};
